<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 09:49:51
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-02 18:01:46
 * @Description: file content
-->
<template>
  <div class="TopBar">
    <div class="topbar-left">
      <span class="left-front">{{$t('register.title')}}｜</span>
      <div class="left-back">{{stationName}}<br/>
        <span class="left-addr">{{stationAddress}}</span>
      </div>
    </div>
    <div class="topbar-right">
      <div class="right-lang">
        <lang class="lang"/>
      </div>
      <p class="right-line"/>
      <AvatarName/>
    </div>
  </div>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-14
 * @router('/TopBar')
 */
import { mapGetters } from "vuex";
import AvatarName from './Avatar'
import * as util from "@/utils/index";
export default {
  name: "TopBar",
  components: {AvatarName},
  props: {},
  data() {
    return {
    }
  },

  computed: { ...mapGetters(["sidebar", "avatar", "stationName", "stationAddress"]) },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    }
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.TopBar {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  .topbar-left {
    // flex: 1;
    //width: 38%;
    display: flex;
    align-items: center;
    padding-left: 18px;
     white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    .left-front {
      color: #fff;
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .left-back {
      color: #70a436;
      font-size: 18px;
      line-height: normal;
      height: auto;

      .left-addr {
        color: #70a436;
        font-size: 14px;
        line-height: normal;
        height: auto;
      }
    }
  }
  .topbar-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .right-lang {
      display: flex;
      align-items: center;
      height: 60px;
      min-width: 108px;
      margin-left: 8px;
      line-height: 30px;
      flex-direction: row-reverse;
    }
    .right-line {
      width: 1px;
      height: 40px;
      background: #dddfe5;
      margin-left:15px;
    }
    .right-avatar {
      display: flex;
      align-items: center;

      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #dddfe5;
        margin: 0 18px;
      }
      .user-logout {
        color: #70a436;
        font-size: 16px;
        margin-right: 18px;
        cursor: pointer;
      }
      .user-name {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
