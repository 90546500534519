<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-02 23:46:18
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-23 18:11:12
-->
<template>
  <div class="unStation">
    <GnGrid
      ref="grid"
      :url="getStationListsAPI"
      :params="gridOptions"
      :methods="'get'"
      style="width: 100%"
      :render-content="gridRenderContent"
      class="report-grid"
    />
  </div>
</template>

<script>
import Confirm from "@/mixins/Confirm";
import store from "@/store";
import rowCloum from "@/mixins/rowCloum";
import { stationRemove } from "@/api/station";
import * as util from "@/utils";

/**
 * @author: JIXUYU
 * @since: 2021-11-02
 * @router('/unStation')
 */
export default {
  name: "unStation",
  components: {},
  mixins: [Confirm, rowCloum],
  props: {
    gridOptions: {
      type: Object,
      default() {
        return {
          installed: false,
        };
      },
    },
  },
  data() {
    return {
      getStationListsAPI: "/station/list",
    };
  },
  methods: {
    /**
     * @description: 电站列表数据渲染
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    gridRenderContent() {
      return [
        {
          label: this.$t("stationReport.name"),
          render: (scope) => <span>{this.MixinsClounm(scope.row.name)}</span>,
        },
        {
          label: this.$t("stationReport.address"),
          render: (scope) => (
            <span>
              {
                localStorage.getItem('lang')==='zh'?
                util.defaultValue(scope.row.country) +" "+
                util.defaultValue(scope.row.province) +" "+
                util.defaultValue(scope.row.city) +" "+
                util.defaultValue(scope.row.district)
                :
                util.defaultValue(scope.row.district)+" "+
                util.defaultValue(scope.row.city) +" "+
                util.defaultValue(scope.row.province) +" "+
                util.defaultValue(scope.row.country)
              }
            </span>
          ),
        },
        {
          label: this.$t("stationReport.Continue"),
          render: (scope) => (
            <div>
              <el-button
                type="success"
                onClick={() => this.handleSet(scope.row)}
              >
                {this.$t("stationReport.Continue")}
              </el-button>
            </div>
          ),
        },
        {
          label: this.$t("stationReport.operation"),
          render: (scope) => (
            <div>
              <el-button
                type="danger"
                onClick={() => this.handleDel(scope.row)}
              >
                {this.$t("stationReport.delete")}
              </el-button>
            </div>
          ),
        },
      ];
    },
    /**
     * @description: 继续安装操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handleSet(val) {
      const station = util.readSession("userInfo");
      station["stationId"] = val.id;
      station["stationName"] = val.name;
      util.writeSession("stationId", val.id);
      this.$store.dispatch("user/changeStation", val.name);
      let addr = localStorage.getItem('lang')==='zh'?
                util.defaultValue(val.country) +" "+
                util.defaultValue(val.province) +" "+
                util.defaultValue(val.city) +" "+
                util.defaultValue(val.district)
                :
                util.defaultValue(val.district)+" "+
                util.defaultValue(val.city) +" "+
                util.defaultValue(val.province) +" "+
                util.defaultValue(val.country);
      this.$store.dispatch("user/changeStationAddress", addr);

      this.$router.push({ path: "/config", query: { stationId: val.id } });
    },
    /**
     * @description: 删除操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    async handleDel(val) {
      const results = await this.MixinsConfirm(this.$t("stationReport.isDelete"));
      if (results) {
        stationRemove({ ids: val.id }).then((res) => {
          if (res.code == 200) {
            this.$refs.grid.tableInitial(false);
          }
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../style/index";
</style>
